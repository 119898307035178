import * as actions from "../actionTypes";
import {
  API_BASE_URL,
  loginURL,
  forgotPasswordURL,
  createUserURL,
  resetPasswordURL,
  resendVerificationEmailURL,
  accountActicationUrl,
  cIdUrl,
  newEmailConfirm,
  newEmailReq,
  newEmailConfirmReq,
  deleteAccountURL,
  verifyDeleteAccountURL,
} from "../../utils/urls";
import http from "../../utils/http-service";
import { fetchUser } from "./user";
import {encrypt,decrypt} from "../../utils/encryption";
// import showToast from "../../utils/show-toast";
import ShowToast from "../../utils/show-toast";



const loginStart = () => {
  return { type: actions.LOGIN_START };
};

const loginFailed = () => {
  return { type: actions.LOGIN_FAILED };
};

export const loginSuccess = (payload) => {
  return { type: actions.LOGIN_SUCCESS, payload };
};


export const login = (data) => async (dispatch) => {
  try {
    dispatch(loginStart());
    let { email, password } = data;
email = email.toLowerCase();
    let loginData = {
      email,
      password,
      app:"web"
    };
    loginData = JSON.stringify(loginData);

    loginData = encrypt(loginData);

    const result = await http.post(`${API_BASE_URL}${loginURL}`, {
      data: loginData
    });

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = decrypt(data);
      data = JSON.parse(data);

      localStorage.setItem("grxAuthToken", data.token);
      dispatch(loginSuccess(data));


      dispatch(fetchUser());
    }

    return result;
  } catch (error) {
    // ////(error.response);
    // ShowToast(error.response.data.message,"error");
// ////("printed");
    dispatch(loginFailed());
    throw error;
  }
};

export const logout = (tokenExpiry) => {
  ////("logout");
  if(tokenExpiry && window.location.pathname !== "/auth/login" && window.location.pathname !== "/dashboard" && window.location.pathname !== "/export-data"){

    localStorage.setItem("pathname", window.location.pathname);

  }
  localStorage.removeItem("grxAuthToken");
  localStorage.removeItem("AidexAuthToken");

  localStorage.removeItem("doc");
  localStorage.removeItem(encrypt("UserId"))
  localStorage.removeItem(encrypt("pendingInviteAcceptToken"));
  localStorage.removeItem("export");

  return {
    type: actions.USER_LOGGED_OUT,
  };
};

const registerStart = () => {
  return { type: actions.REGISTER_START };
};

const registerFailed = () => {
  return { type: actions.REGISTER_FAILED };
};

const registerSuccess = (payload) => {
  return { type: actions.REGISTER_SUCCESS, payload };
};

export const register = (data) => async (dispatch) => {
  try {
    dispatch(registerStart());
    let registerData={...data,role:"patient"}

    registerData=JSON.stringify(registerData)
    registerData = encrypt(registerData);
    const result = await http.post(`${API_BASE_URL}${createUserURL}`, {"data":registerData});
    ////(result);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      // localStorage.setItem("grxAuthToken", data.token);
      data= decrypt(data)
      data=JSON.parse(data)

      delete data.token;
      dispatch(registerSuccess(data));
      // showToast(err.response.data.message,"error");
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(registerFailed());
    throw error;
  }
};

const forgotPasswordStart = () => {
  return { type: actions.FORGOT_PASSWORD_START };
};

const forgotPasswordFailed = () => {
  return { type: actions.FORGOT_PASSWORD_FAILED };
};

// const forgotPasswordSuccess = (payload) => {
//   return { type: actions.FORGOT_PASSWORD_SUCCESS, payload };
// };

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(forgotPasswordStart());
    let forgetData=data
    forgetData=JSON.stringify(forgetData)
    forgetData=encrypt(forgetData)
    const result = await http.post(`${API_BASE_URL}${forgotPasswordURL}`, {
      "data":forgetData
    });

    return result;
  } catch (error) {

    dispatch(forgotPasswordFailed());
    throw error;
  }
};

const resetPasswordStart = () => {
  return { type: actions.RESET_PASSWORD_START };
};

const resetPasswordFailed = () => {
  return { type: actions.RESET_PASSWORD_FAILED };
};

// const resetPasswordSuccess = (payload) => {
//   return { type: actions.RESET_PASSWORD_SUCCESS, payload };
// };

export const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(resetPasswordStart());
    let resetData=data
    resetData=JSON.stringify(resetData)
    resetData=encrypt(resetData)

    const result = await http.post(`${API_BASE_URL}${resetPasswordURL}`, {
      "data":resetData
    });


    return result;
  } catch (error) {
    dispatch(resetPasswordFailed());
    throw error;
  }
};

const resendVerificationEmailStart = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_START };
};

const resendVerificationEmailFailed = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_FAILED };
};

const resendVerificationEmailSuccess = (payload) => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_SUCCESS, payload };
};

export const resendVerificationEmail = (data) => async (dispatch) => {
  try {
    dispatch(resendVerificationEmailStart());
    let resendEmailData=data
    resendEmailData=JSON.stringify(resendEmailData)
    resendEmailData=encrypt(resendEmailData)
    const result = await http.post(
      `${API_BASE_URL}${resendVerificationEmailURL}`,
      {
       "data":resendEmailData
      }
    );


    if (result.status === 200) {
      // const {
      //   data: { data },
      // } = result;

      // data=decrypt(data);
      // data=JSON.parse(data);
      dispatch(resendVerificationEmailSuccess(data));
    }

    return result;
  } catch (error) {
    dispatch(resendVerificationEmailFailed());
    throw error;
  }
};
export const accountActivation = (token) => async (dispatch) => {
  let result;
  try {
    let setToken;

    // token=JSON.stringify(token);
    setToken= {"token":token};
    setToken=JSON.stringify(setToken);

    setToken=encrypt(setToken);

    result = await http.post(`${API_BASE_URL}${accountActicationUrl}`,{"data":setToken});


    ShowToast(result.data.message,"success");
    return result;

  } catch (error) {
    //("Cached",error.response);

    dispatch(resetPasswordFailed());
    if( error.response.status===409){
      ShowToast(error.response.data.message,"success");
    }
    else{
      ShowToast(error.response.data.message,"error");
    }
    throw error;
    //  return error.response;
  }
};

// update emial Request
export const resetEmail=(email)=> async(dispatch)=>{
try {
  const res = await http.get(`${API_BASE_URL}${newEmailReq}`);
// ShowToast(res.data.message,"success");
return res;
} catch (error) {
  if( error.response.status===409){
    // ShowToast(error.response.data.message,"success");
  }
  else{
    ShowToast(error.response.data.message,"error");
  }
  throw error;
}
}



// email update confirm
export const resetEmailConfirm=(email,token)=> async(dispatch)=>{
// // console.log("TOKEN :", token);
try {
  const res = await http.post(`${API_BASE_URL}${newEmailConfirmReq}`,{"email":email,"token":token});
ShowToast(res.data.message,"success");
return res;
} catch (error) {
  if( error.response.status===409){
    ShowToast(error.response.data.message,"success");
  }
  else{
    ShowToast(error.response.data.message,"error");
  }
  throw error;
}
}

// email update req confirm
export const resetEmailConfirmReq=(token)=> async(dispatch)=>{
// console.log("TOKEN :", token);
try {
  const res = await http.post(`${API_BASE_URL}${newEmailConfirm}`,{"token":token});
// ShowToast(res.data.message,"success");
dispatch(logout());
return res;
} catch (error) {
  if( error.response.status===409){
    ShowToast(error.response.data.message,"success");
  }
  else{
    ShowToast(error.response.data.message,"error");
  }
  throw error;
}
}




// Client Id for AlexaIntegration

export const checkClientId = (cId) => async (dispatch) => {
  let result;
  try {


    // token=JSON.stringify(token);
    let setCID= cId;
    // console.log("cId", setCID);
    // setCID=JSON.stringify(setCID);

    setCID=encrypt(setCID);

    result = await http.get(`${API_BASE_URL}${cIdUrl}?clientId=${setCID}`);


    // ShowToast(result.data.message,"success");
    return result;

  } catch (error) {
    //("Cached",error.response);


      // ShowToast(error.response.data.message,"error");

    throw error;
     return error.response;
  }
};

export const deleteAccountReq = () => async (dispatch) => {

  try {

    const  result = await http.get(`${API_BASE_URL}${deleteAccountURL}`);

    // // console.log("RESULT :", result);
    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return result;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    throw error;
  }
};

export const deleteAccountConfirm = (token) => async (dispatch) => {
let tok = encrypt(token);

  try {

    const  result = await http.delete(`${API_BASE_URL}${verifyDeleteAccountURL}?token=${tok}`);

    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return true;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    throw error;
  }
};

export const safariCheck = (payload) => {
  return { type: actions.SAFARI_CHECK, payload };
};
export const safariStatus = () => async (dispatch) => {
  let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  dispatch(safariCheck(isSafari));
};
