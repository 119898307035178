import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import DocumentTitles from "../../utils/document-titles";
import Medications from "./medications";
import HistoricalReading from "../patient-dashboard/micro-apps/BloodGlucose/HistoricalReading";
import ProductAds from "./product-ads";
import { useSelector, useDispatch } from "react-redux";
import { fetchMedication } from "../../redux/actions/medication";
import { Tabs, Spin } from "antd";
import medicineTransformer from "../../utils/transformers/medicine-transformer";
import { Vitals } from "./vitals";
import {
  getHistoricalReadings,

} from "../../redux/actions/patient-dashboard";
import { getDevices } from "../../redux/actions/device";
import BgRating from "./micro-apps/BloodGlucose/BgRating";
import { decrypt } from "../../utils/encryption";
import ExportData from "./Export/ExportData";
import UserProfileModal from "../common/UserProfileModal";
import HistoricalReadingsChart from "./historical-readingsBg";
import DoctorShareInviteModal from "./DoctorShareInvite/DoctorShareInviteModal";



// import { PDF } from "../pdf";
// import ReactToPdf  from 'react-to-pdf';
export default function PatientDashboard() {
  let url = window.location;
  let access_token = new URLSearchParams(url.search).get('token');
  let token=access_token && access_token.slice(0,228);
  const [checkToken, setCheckToken] = useState(token);

  const device = useSelector((state) =>(state.devices.devices));
  const ref = React.createRef();
  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [timeAndGlucose, setTimeAndGlucose] = useState({});
  const [ads, setAds] = useState([]);
  const [page,setPage]=useState("1")
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  document.title = DocumentTitles.dashboard;
  const dispatch = useDispatch();
  const [medications, setMedications] = useState([]);
  const { medicationData, isLoading: isMedicationLoading } = useSelector(
    (state) => state.medication
  );
  const [exportLoad, setExportLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setExportLoad(true);
    }, 6000);
  });


  useEffect(() => {
    dispatch(getDevices());
    fetchHistory();
    //console.clear();
  }, []);

  let record=4;
  useEffect(()=>{
    dispatch(fetchMedication({page,record}));
    //console.clear();

  },[page])

  useEffect(() => {
    if (!medicationData) {
      return;
    }

    setMedications(
      medicationData && medicationData.medications
      .map((med) =>
        medicineTransformer.createDetailsObjectFromRequest(med)
      )
    );
    //console.clear();

  }, [medicationData,page]);

  const handlePageNumber=(page)=>{

      setPage(page)
  }

  const fetchHistory = async () => {
    setIsHistoryLoading(true);
    let res = await getHistoricalReadings();

    let data = res.data.data;
    data = decrypt(data);
    data = JSON.parse(data);
    transformHistoricalReadings(data.historicalReadings);
    setTimeAndGlucose(data.timeAndGlucose);
    setAds(data.ads);

    setIsHistoryLoading(false);
  };



  ////
  const transformHistoricalReadings = (data) => {
    let transformedData = [];
    //("data.historicalReadings=",data.historicalReadings)
    transformedData = data

        .map((reading) => {

          return reading.data.map((item, index) => {
const date = new Date(item.year, item.month - 1, reading._id, item.hour, item.minutes);
let obj = {
  date: date,
  reading: item.value["bloodGlucose"],
  time: item.tmie,
};
// console.log("OBJECT :", obj);
return obj;

          });
        })
        .flat();

    let myArray = _.sortBy(transformedData.flat(), function(dateObj) {

      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray);
  };
  ////

  // const transformHistoricalReadings = (data) => {
  //   //("Historical reading=",  data)
  //   let transformedData = [];
  //   transformedData = data
  //     .map((reading) => {
  //       return reading.data.map((item) => {
  //         //()
  //         let date = new Date();
  //         date.setDate(reading._id);
  //         date.setMonth(item.month-1);
  //         date.setHours(item.hour);
  //         date.setMinutes(item.minutes);
  //         //("Date Obj=",date);
  //         return {
  //
  //           date: date,
  //           reading: item.value["bloodGlucose"],
  //         };
  //       });
  //     })
  //     .flat();
  //   let myArray = _.sortBy(transformedData.flat(), function (dateObj) {
  //     return new Date(dateObj.date);
  //   });
  //   //console.clear();
  //
  //   setTfHistoricalReadings(myArray);
  // };

  const { TabPane } = Tabs;
  // Bg: true
  // Bp: true
  // PO: true
  // Temp: true
  // Weight: true
  return (
    <Spin spinning={isHistoryLoading} className="h-100">
      <div className="d-flex my-2 mx-3  p-2 pr-1 justify-content-end align-items-center  border-bottom">
       {device.Bg==true || device.Bp==true || device.PO==true || device.Weight==true || device.Temp==true?<ExportData />:null}

      </div>
      <div ref={ref} className="row p-2 mw-100 m-auto
      ">
        {!isHistoryLoading && (
          <div className="col-12">
            <div className="row mw-100 m-auto pb-2">
              <div className="col-12 col-lg-8 mb-lg-0 mb-2 ">
                <div className=" bg-white border-radius-chart p-3 tackle-it h-100">
                  <HistoricalReading
                  margin={false}
                  themeType={"bg"}
                  id={"dashboardhistoryChartBloodglucose"}
                  dataPoints={tfHistoricalReadings}
                  renderOn="dashboardBloodGlucose"
                  />
                </div>
              </div>
              {/*<Events*/}
              <div className="col-12 col-md-6 col-lg-4 mb-lg-0 mb-md-0 mb-2">
                <div className="bg-white border-radius-chart p-3 h-100">
                  <BgRating
                    margin={false}
                    height={"75%"}
                    width={"75%"}
                    themeType={"bg"}
                    renderOn={"dashboard"}
                    timePercentage={
                      timeAndGlucose.inRangePercentage &&
                      parseInt(timeAndGlucose.inRangePercentage)
                    }
                    glucosePercentage={
                      timeAndGlucose.highEventsPercentage &&
                      parseInt(
                        timeAndGlucose.highEventsPercentage +
                          timeAndGlucose.lowEventsPercentage
                      )
                    }
                    timeDataPoints={[
                      {
                        label: "In-Range",
                        value: parseInt(timeAndGlucose.inRangePercentage),
                      },
                      {
                        label: "Out-Range",
                        value: 100 - parseInt(timeAndGlucose.inRangePercentage),
                      },
                    ]}
                    glucoseDataPoints={[
                      {
                        label: "High-events",
                        value: parseInt(timeAndGlucose.highEventsPercentage),
                      },
                      {
                        label: "Low-events",
                        value: parseInt(timeAndGlucose.lowEventsPercentage),
                      },
                      {
                        label: "In-Range",
                        value: parseInt(timeAndGlucose.inRangePercentage),
                      },
                    ]}
                  />
                </div>
              </div>

            </div>
            {/* Second Row */}
            <div className="row mw-100 m-auto ">
            <div className="col-12">
            <DoctorShareInviteModal modal={checkToken? true: false}/>

            </div>
              <div className="col-12 col-lg-8 mb-lg-0  mb-lg-0 mb-2">
                <div className=" bg-white border-radius-chart p-3 h-100 tackle-it">
                <Vitals
                />
                 </div>
              </div>
              <div className="col-12 col-lg-4 mb-lg-0 ">
                <div className=" bg-white border-radius-chart  h-100 tackle-it">
                <Medications
            medications={medications}
            isLoading={isMedicationLoading}
            pageNumber={medicationData && medicationData.totalPage}
            settingPage={handlePageNumber}
          />


                 </div>
              </div>
            </div>



            {/* Third Row */}
            <div className="row w-100 d-md-none m-auto d-block">
            <div className="col-12 mt-2">
                <div className="bg-white border-radius-chart p-3 h-100">
                  <ProductAds  />
                </div>
              </div>

            </div>

          </div>
        )}
      </div>
    </Spin>
  );
}
