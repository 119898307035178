import * as actions from "../../actionTypes";
import http from "./aidex-http-service";
import ShowToast from "../../../utils/show-toast";
import crypto from "crypto";



const aidexLoginStart = () => {
  return { type: actions.AIDEX_LOGIN_START };
};

const aidexLoginFailed = () => {
  return { type: actions.AIDEX_LOGIN_FAILED };
};

export const aidexLoginSuccess = (payload) => {
  return { type: actions.AIDEX_LOGIN_SUCCESS, payload };
};

export const aidexLogin = (data) => async (dispatch) => {

  try {
    dispatch(aidexLoginStart());
    const { email, password } = data;
    let hash = crypto.createHash('sha256');//passing the data to be hashed
let pass_charset = hash.update(password, 'utf-8');//Creating the hash in the required format
let hash_pass= pass_charset.digest('hex');//Printing the output on the console

    const result = await http.post(`https://grxaidex.co.uk/api/login`, {
      email,
      password:hash_pass,

    });

    if (result.status === 200 && result.data.code !== 404 && result.data.code !== 400) {
      // // // // console.log("RESULT>STATUS :", result);
      let data = result.data;

      let token = data.content.token;
      localStorage.setItem("AidexAuthToken", token);
      dispatch(aidexLoginSuccess(data));
    } else {
      ShowToast("Can you please check your email or password","error");
    }

    return result;
  } catch (error) {
    ShowToast(error.response.data.message,"error");

    dispatch(aidexLoginFailed());
    throw error;
  }
};
export const aidexUserloggedout=()=>{
    return { type: actions.AIDEX_USER_LOGGED_OUT };
}

export const aidexLogout = () =>(dispatch)=> {

  localStorage.removeItem("AidexAuthToken");
  dispatch(aidexUserloggedout());
  return true;
};
