import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { microAppTypes } from "../../utils/micro-app";
const WEIGHT = require("../../assets/GRx Weight Scale-2.jpeg");
const BP = require("../../assets/blood_pressure_meter.png");
const TEMP = require("../../assets/GRx Forehead Thermometer Pro.png");
const PO = require("../../assets/GRx Fingertip Pulse Oximeter.png");
const Nexus = require("../../assets/products/GlucoRxNexusMeter.png");
const DONGLE = require("../../assets/products/GRxDONGLE.png");
const HCT = require("../../assets/products/GlucoRx-Products/Hct.png");
const MiniUltra = require("../../assets/products/GlucoRx-Products/miniUltra.png");
const NexusBlue = require("../../assets/products/GlucoRx-Products/nexuxBlue.png");
const Pearl = require("../../assets/products/GlucoRx-Products/pearl.png");
const Nexux = require("../../assets/products/GlucoRx-Products/nexux.png");
const QBlood = require("../../assets/products/GlucoRx-Products/QBlood.png");
const VoiceMeter = require("../../assets/products/GlucoRx-Products/voiceMeter.png");
const GlucoRXGo = require("../../assets/products/GlucoRx-Products/GlucoRXGo.png");



const DeviceCard = ({ macAddress, identifier, name, type, id,status,createdAt}) => {
  // console.log("TYPE", type);
  // console.log("Status", status);

  let image;
  const img={

    width:  "180px",
    height: "180px",
    // backgroundSize: "cover",
    objectFit:"contain",
}

  if (type === 1) {
    //("0", image);
    if (name === "HCT Meter" || name === "TD-4279") {
      name = "GlucoRx HCT Blood Glucose Meter";
      image = HCT;
      //("1", image);
    }
    if (name === "Nexus Meter" || name === "TD-4277") {
      name = "GlucoRx Nexus Blood Glucose Meter";
      image = Nexux;
      //("2", image);
    }
    if (name === "Nexus Mini Ultra" || name === "TD-4141") {
      name = "GlucoRx Mini Ultra Blood Glucose Meter";
      image = MiniUltra;
      //("3", image);
    }
    if (name === "Q Meter" || name === "TD-4235B") {
      name = "GlucoRx Q Blood Glucose Meter";
      image = QBlood;
    }
    if (name === "Nexus Voice Meter" || name === "TD-4280") {
      name = "GlucoRx Nexus Voice Blood Glucose Meter";
      image = VoiceMeter;
    }
    if (name === "Nexus Pearl" || name === "TD-4277C") {
      name = "GlucoRx Pearl Blood Glucose Meter";
      image = Pearl;
    }

    if (name === "GlucoRx Nexus Blue" || name === "TD-4277B") {
      name = "GlucoRx Nexus BLUE Blood Glucose Meter";
      image = NexusBlue;
    }
  }

  if (type === 2) {
    name = "Blood Pressure Monitor";
    image = BP;
  }
  if (type === 3) {
    name = "Forehead Thermometer";
    image = TEMP;
  }
  if (type === 4) {
    name = "Fingertip Pulse Oximeter";
    image = PO;
  }
  if (type === 5) {
    name = "Weight Scale";
    image = WEIGHT;
  }
  if (type === 6) {
    name = "Step Count";
  }
  if (type === 7) {
    name = "GlucoRx Dongle";
    image = DONGLE;
  }
  if (type === 14) {
    name = "Grx Go";
    image = GlucoRXGo;
  }
  if (type === 15) {
    name = "Vision GlucoRx Go";
    image = GlucoRXGo;
  }
  if (type === 16) {
    name = "Vision GlucoRx HTC";
    image = HCT;
  }
  if (type === 17) {
    name = "Vision GlucoRx Q";
    image = QBlood;
  }
  if (type === 18) {
    name = "Vision GlucoRx Nexus";
    image = NexusBlue;
  }
  if (type === 19) {
    name = "Vision GlucoRx MiniUltra";
    image = MiniUltra;
  }
  if (type === 20) {
    name = "Vision GlucoRx Nexus Voice";
    image = VoiceMeter;
  }
  if (type === 28) {
    name = "GlucoRx Pearl Blood Glucose Meter";
    image = Pearl;
  }
 const address=macAddress!=="" && identifier!==""?macAddress:macAddress && macAddress!=""?macAddress:identifier && identifier!==""?identifier:null
  return (
    <>
      {status === 1 && type !== 6 && type !== 10?
    // <div className="m-2  w-full bg-white bg-voyger-blue div-border-radius  shadow-lg flex justify-center items-center flex-col">

    //   <p className="w-full p-2 bg-voyger-blue text-2xl text-center text-bold text-white">
    //     {name}
    //   </p>
    //   <div className="p-2 flex flex-col md:flex-row w-full items-center justify-evenly">
    //     <div>
    //       <img src={image} style={{ height: "100px", width: "150" }} />
    //     </div>
    //     <div className="flex flex-col">
    //       {macAddress || identifier ?
    //       (macAddress ? (
    //         <div className="flex flex-row justify-between">
    //           {macAddress && macAddress != "" ? (
    //             <p className="mr-2 text-muted">Mac Address :</p>
    //           ) : null}

    //           {/* <p className="mr-2 text-muted">ID :</p>{" "} */}
    //           <p>{macAddress ? macAddress : null}</p>
    //         </div>
    //       ) : null)
    //       :null}


    //     {macAddress || identifier  ?
    //     null :
    //      (identifier ? (
    //         <div className="flex flex-row justify-between">
    //           {identifier && identifier != "" ? (
    //             <p className="mr-2 text-muted">Mac Address:</p>
    //           ) : null}

    //           <p>{identifier ? identifier : null}</p>
    //         </div>
    //       ) : null)

    //     }

    //          <div className="flex flex-row">
    //         <p className="mr-2 text-muted">Device Type:</p>{" "}
    //         <p>{microAppTypes[type]}</p>
    //       </div>
    //     </div>

    //   </div>
    // </div>
    <div className='col-md-5 col-10 mw-100  mt-4 '>
      <div className="d-flex flex-column justify-content-between shadow" style={{backgroundColor:"#F4F9FC",height:"100%",borderRadius:"18px"}}>
      <div className=" text-white text-center h5" style={{background: "#21608A" ,borderTopLeftRadius:"15px",borderTopRightRadius:"15px",}}>
      <p className='py-2 px-2'>{name}</p>

      </div >
      <div className="d-flex justify-content-center">
         <img src={image} style={img} alt={name}/>
      </div >
      <div className='mb-3 mt-2'>
      <div className='text-gray-100 text-xs'>
        <div className="flex flex-row justify-content-center text-gray-100 ">
           <p className="mr-2 text-secondary font-weight-bold">Added At:</p>{" "}
           <p className="text-muted">{moment(createdAt).format('DD MMM YYYY, h:mm:ss A')}</p>
        </div>

      {/* {macAddress || identifier ?
          (macAddress ? (
            <div className="flex flex-row justify-content-center">
              {macAddress && macAddress != "" ? (
                <p className="mr-2 text-secondary font-weight-bold">Mac Address :</p>
              ) : null}
              <p className="text-muted">{macAddress ? macAddress : null}</p>
            </div>
          ) : null)
          :null} */}

          {/* <div className="d-flex flex-row justify-content-center align-items-center">

                <p className="mr-2 text-secondary font-weight-bold">Mac Address :</p>

              <p className="text-muted">{address}</p>
            </div> */}


        {/* {macAddress || identifier  ?

         (identifier ? (
            <div className="flex flex-row justify-content-center">
              {identifier && identifier != "" ? (
                <p className="mr-2 text-secondary font-weight-bold">Mac Address:</p>
              ) : null}

              <p className="text-muted">{identifier ? identifier : null}</p>
            </div>
          ) : null)
         :null
        } */}
        </div>
        {/* <div className="flex flex-row justify-content-center text-gray-100 font-size-big">
           <p className="mr-2 text-secondary font-weight-bold">Device Type:</p>{" "}
           <p className="text-muted">{microAppTypes[type]}</p>
        </div> */}
     </div>

    </div>
    </div>
      :null}

    </>
  );
};

export default DeviceCard;
