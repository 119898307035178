import React, { Component } from "react";
import SideBar from "./SideBar";
import { logout } from "../../redux/actions/auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getDevices } from "../../redux/actions/device";
import $ from "jquery";
import NewHeader from "./NewHeader";
import DoctorShareInviteModal from "../patient-dashboard/DoctorShareInvite/DoctorShareInviteModal";
import { decrypt, encrypt } from "../../utils/encryption";
import { doctorInfo } from "../../redux/actions/Share/share";

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      user: [],
      role: "",
      path: "",
      shareToken: "",
      opened: true,
      userId: "",
      url: "",
      urlUserId: "",
      access_token: "",
      id: "",
      modal_open: false,
      doctorInfo:[]
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    //(nextProps);
    console.warn = () => {};
if(localStorage.getItem("grxAuthToken") === null){
  logout(false);
}else{
  this.setState({
    role: nextProps.role,
    path: nextProps.location.pathname,
    shareToken: nextProps.shareToken,
    userId: nextProps.userId,
    user: nextProps.user,
    urlUserId: nextProps.urlUserId,
    doctorInfo:nextProps.doctorInfo
  });
}




    this.setState({
      role: nextProps.role,
      path: nextProps.location.pathname,
      shareToken: nextProps.shareToken,
      userId: nextProps.userId,
      user: nextProps.user,
      urlUserId: nextProps.urlUserId,
      doctorInfo:nextProps.doctorInfo
    });

    if (nextProps.user.userDetails) {
      this.tokenCheck();
    }

    let local_shareToken =localStorage.getItem(encrypt("pendingInviteAcceptToken"));
    local_shareToken=local_shareToken!==null && local_shareToken.length>0 ?decrypt(local_shareToken):null;
    let local_doctorId =localStorage.getItem(encrypt("UserId"))
   local_doctorId=local_doctorId!==null && local_doctorId.length>0 ?decrypt(local_doctorId):null;
    if (
      local_shareToken &&
      local_shareToken !== "" &&
      this.state.opened
    ) {

      if(nextProps.doctorInfo){
      if (nextProps.userId && nextProps.userId.toString() === local_doctorId.toString()) {
        if(window.location.pathname === "/dashboard"){
          this.setState({ opened: false });
        // document.querySelector(".submit.logout").click();
        }

      }}
    }

    if (nextProps.location.pathname === "/export-data") {
      if (document.getElementsByTagName("body")) {
        var body = document.getElementsByTagName("body")[0];
        //("Body==",body);
        //("Body classlist==",body.classList);
        body.setAttribute("class", "scrolling-fix");
      }
    } else {
      if (document.getElementsByTagName("body")) {
        var body = document.getElementsByTagName("body")[0];
        //("Body==",body);
        //("Body classlist==",body.classList);
        body.setAttribute("class", "");
      }
    }
    setTimeout(function () {
      $("#root").siblings("div").remove();
    }, 5000);
  }

  componentDidMount() {
    //(this.props.location.pathname);
    let local_doctorId =localStorage.getItem(encrypt("UserId"))
   local_doctorId=local_doctorId!==null && local_doctorId.length>0 ?decrypt(local_doctorId):null;
    this.props.dispatch(getDevices());
    if(window.location.pathname !== "/user/deleteAccount"){
    this.props.dispatch(doctorInfo({ local_doctorId }));
  }

    this.setState({ path: this.props.location.pathname });
    var url = window.location;
    var access_token = new URLSearchParams(url.search).get('token');
    var token=access_token && access_token.slice(0,228)
    var id=access_token && access_token.slice(232, access_token && access_token.length)
    id && localStorage.setItem(encrypt("UserId"),encrypt(id.toString()))
    token && localStorage.setItem(encrypt("pendingInviteAcceptToken"),encrypt(token.toString()))
    this.setState({ url: url });
    this.setState({ access_token: access_token });
    this.setState({ id: this.state.id });

    setTimeout(function () {
      $("#root").siblings("div").remove();
    }, 5000);

    //("Shared layout =",this.props);
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  logout = () => {
    this.props.logout(false);
  };

  tokenCheck = () => {
    if (

      this.state.access_token &&
      this.state.access_token.slice(232, this.state.access_token.length) ===
        this.state.userId
    ) {
      if (
        this.state.access_token !== null &&
        this.state.access_token !== "" &&
        this.state.access_token !== undefined
      ) {
        if (window.location.pathname === "/dashboard") {
          this.setState({ modal_open: true });
        }
      }
    }
  };

  render() {
    if (localStorage.getItem("grxAuthToken") === null) {

      logout(false);

    }
    // if(this.state.access_token && this.state.userId && this.state.userId !== null && this.state.userId !== ""){
    //   // this.setState({check:true})
    //   if(this.state.check === true) {
    //     this.tokenCheck();
    //   }
    // }
    return (

      <>
        {window.location.pathname !== "/export-data" ? (

          <div className=" h-100 bg-white">
          {window.location.pathname !== "/user/deleteAccount"?
          <NewHeader/>:null}

          <div className="row h-100 bg-light rounded mx-1  py-2">
          {window.location.pathname !== "/user/deleteAccount"?

      <aside className="col-12 col-lg-2 mx-0 py-lg-3 py-2 mb-lg-0 mb-2 fixed-top  " style={{ background:"rgb(0, 54, 80)",borderRadius:"1em" }}>
       <SideBar/>
      </aside>
      :null}
      <main className={window.location.pathname !== "/user/deleteAccount"?"col-12 col-lg-10 h-100 bg-faded main-dashboard":"col-12 col-lg-12 h-100 bg-faded main-dashboard"}>
      {this.props.children}
     {/* <CompareComp/> */}
       </main>
  </div>
</div>

        ) : (

           <>
            <NewHeader />
            <div className="row  bg-light  m-auto">
              <aside
                className="col-12 col-lg-2 site-layout-background nav-bg d-lg-flex flex-lg-column align-items-md-center fixed-top"
                style={{ background: "#003650" }}
              >
                <SideBar />
               {/* <span className="d-lg-block d-none mb-5"><ProductAds/></span>  */}
              </aside>

              <main className="col grx-main bg-faded main-dashboard">
                <DoctorShareInviteModal modal={this.state.modal_open} />
                {this.props.children}
              </main>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    auth: state.auth,
    shareToken: state.doctor.shareToken,
    role:
      state.user && state.user.userDetails ? state.user.userDetails.role : null,
    userId:
      state.user && state.user.userDetails ? state.user.userDetails._id : null,
    user: state.user && state.user ? state.user : null,
    urlUserId: state.doctor ? state.doctor.userId : null,
    doctorInfo:state.share.doctorInviteInfo?state.share.doctorInviteInfo:null
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout,
  dispatch,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharedLayout)
);
