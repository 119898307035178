import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import $ from "jquery";
import Tooltip from "../../../../utils/Tooltip";

am4core.useTheme(am4themes_animated);

function Chart6(props) {
  // // // // // console.log(props);

// 
let heading=props.themeType==="bg"?"text-voyger-purple":props.themeType==="inr"?"text-voyger-inr-blue":"text-voyger-purple";
let color1=props.themeType==="bg"?"#0D92D2":props.themeType==="inr"?"#99D7A6":"#0D92D2";
let color2=props.themeType==="bg"?"#AD3860":props.themeType==="inr"?"#5C91AA":"#AD3860";
let color3=props.themeType==="bg"?"#7C1237":props.themeType==="inr"?"#818FAE":"#7C1237";

  useEffect(() => {
    // // // // // console.log(props);
    let total = props.total;
    let hyper = props.hyper;
    let hypo = props.hypo;
     let hyperAvg = total>0?(hyper / total) * 100:0;
    //  if(total>0){hyperAvg = (hyper / total) * 100;}else{hyperAvg = 0;}
     let hypoAvg = total>0 ?(hypo / total) * 100:0;
    //  if(total>0){hyperAvg = (hypo / total) * 100;}else{hypoAvg = 0;}
     
let data = [{label: "hyper", value: hyperAvg+ 0.0000000000000000000000000000000000000000000000001},{label:"hypos", value: hypoAvg+ 0.0000000000000000000000000000000000000000000000001}]

    let chart = am4core.create("chartdiv6", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.data = data;
    chart.radius = am4core.percent(90);
    chart.innerRadius = am4core.percent(60);
    chart.startAngle = 180;
    chart.endAngle = 360;

    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "label";

    series.slices.template.cornerRadius = 5;
    series.slices.template.innerCornerRadius = 3;
    series.slices.template.draggable = true;
    series.labels.template.disabled = true;

    series.slices.template.inert = true;
    series.alignLabels = false;

    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;

    const label = series.createChild(am4core.Label);
    label.text = `${props.hyper === null && props.hypos === null ? 0 :
      props.hyper !== null && props.hypo === null? hyperAvg :
      props.hyper === null && props.hypo !== null? hypoAvg :
      props.hyper !== null && props.hypo !== null &&
      props.hyper >= props.hypo?hyperAvg : hypoAvg} %`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 24;
    label.fontWeight = "bold";
    label.fill = color2;
    // chart.legend = new am4charts.Legend();
    // var hs = series.slices.template.states.getKey("hover");
    // hs.properties.scale = 1;
    // hs.properties.fillOpacity = 0.5;
    series.colors.list = [
      am4core.color(color2),
      am4core.color(color1),
      am4core.color(color3),
    ];

}, [props]);

  return (
    <div className="mt-1 md:mt-0 flex flex-col justify-center items-center">
        <p className={`font-semibold text-md text-center chart-heading m-0 ${heading}`} >Hypos/Hyper
        <span className='ml-2'><Tooltip themeType={props.themeType} text="Hypos /hyper "/></span>
        </p>
        <div id="chartdiv6" style={{ width: "50%", height: "50%" }}></div>
      </div>
  );
}
export default Chart6;
