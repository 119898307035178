import React, { Component } from "react";
import ExportComp from "./ExportComp";
import { Link, useHistory } from "react-router-dom";

class ExportDataComp extends Component {

    state={
        loading:false
    }


    div2PDF = e => {
        window.print();
    }
// handleBack=()=>[
//     history.push("/dashboard")
//   ]

    render() {
        return (
            <div>
                <div  className="" >
                    <div className="d-flex justify-content-between print-btn mt-2">
                        <Link to="/dashboard"> <span className='btn btn-secondary print-btn'>Go back</span></Link>
                        <button className="btn btn-primary print-btn" onClick={e => this.div2PDF(e)}>Export to PDF</button>
                    </div>
                    <div className=" div2PDF m-auto"  >
                        <p className="my-3">{this.state.loading?"Please Wait your Report is Exporting":null}</p>
                        <ExportComp/>
                    </div>
                </div>

            </div>
        );
    }
}

export default  ExportDataComp;
