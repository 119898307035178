export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

// export const API_BASE_URL = "http://localhost:4000";

export const loginURL = "/user/e_login";
// export const createUserURL = "/user/signup";
export const createUserURL = "/user/e_signup";
// export const forgotPasswordURL = "/user/forgotPassword";
export const forgotPasswordURL = "/user/e_forgotPassword";

export const accountActicationUrl ="/user/e_activateAccount";

export const deleteAccountURL = "/user/e_deleteAccount";
export const verifyDeleteAccountURL = "/user/e_verifyDeleteAccount";



// export const resetPasswordURL = "/user/resetPassword";
export const resetPasswordURL = "/user/e_resetPassword";
// export const fetchUserURL = "/user/get";
export const exportPdfUrl = "/user/e_getDataForExport";

export const fetchUserURL = "/user/e_get";

// export const resendVerificationEmailURL = "/user/resend-email-verification";
export const resendVerificationEmailURL = "/user/e_resend-email-verification";

// export const fetchVitalInfoURL = "/history/userHistory/vital_info";
export const fetchVitalInfoURL = "/history/e_userHistory/vital_info";
// export const saveVitalInfoURL = "/history/vitalInfo";
export const saveVitalInfoURL = "/history/e_vitalInfo";

// export const fetchSocialHistoryURL = "/history/userHistory/social_history";
export const fetchSocialHistoryURL = "/history/e_userHistory/social_history";
export const fetchHistoryURL = "/history/e_userHistory/";

// export const saveSocialHistoryURL = "/history/socialHistory";
export const saveSocialHistoryURL = "/history/e_socialHistory";

export const fetchHospitalAdmissionHistoryURL =
    "/history/userHistory/hospital_admissions";
// export const saveHospitalAdmissionHistoryURL = "/history/hospitalAdmission";
export const saveHospitalAdmissionHistoryURL = "/history/e_hospitalAdmission";

export const fetchSurgicalHistoryURL = "/history/userHistory/surgical_history";
// export const saveSurgicalHistoryURL = "/history/surgicalHistory";
export const saveSurgicalHistoryURL = "/history/e_surgicalHistory";

export const fetchFamilyHistoryURL = "/history/userHistory/family_history";
// export const saveFamilyHistoryURL = "/history/familyHistory";
export const saveFamilyHistoryURL = "/history/e_familyHistory";
// export const updateFamilyHistoryURL = "/history/familyHistory";
export const  saveCommonHistoryURL="/history/e_commonCondition";
export const fetchMedicationURL = "/medications/e_getAll";

// export const saveAllergyUrl = "/emergency/allergy";
export const saveAllergyUrl = "/emergency/e_allergy";
// export const fetchAllergyUrl = "/emergency/details/allergies";
export const fetchAllergyUrl = "/emergency/e_details/";

// export const saveChronicConditionUrl = "/emergency/chronicCondition";
export const saveChronicConditionUrl = "/emergency/e_chronicCondition";
// export const fetchChronicConditionUrl = "/emergency/details/chronic_conditions";
export const fetchChronicConditionUrl = "/emergency/e_details/";

// export const saveMedicationUrl = "/emergency/medication";
export const saveMedicationUrl = "/emergency/e_medication";
// export const fetchMedicationUrl = "/emergency/details/current_medications";
export const fetchMedicationUrl = "/emergency/e_details/";

// export const saveImplantedDevicesUrl = "/emergency/implantedDevices";
export const saveImplantedDevicesUrl = "/emergency/e_implantedDevices";
export const fetchImplantedDevicesUrl = "/emergency/e_details/";

// export const fetchBgDataUrl = "/device/getData";
export const fetchBgDataUrl = "/device/e_getData/";


// export const fetchBpDataUrl = "/device/getData";

export const fetchBpDataUrl = "/device/e_getData/";

export const fetchPoDataUrl = "/device/e_getData/";
// AlexaIntegration
export const cIdUrl = "/alexa/e_verifyClientId";



// export const sendFeedback = "/user/feedback";
export const sendFeedback = "/user/e_feedback";

//external doctor
export const fetchShareToken="/doctor/e_tokenForPatientData";
export const fetchSpecificPatient="/doctor/e_searchPatients"
export const doctorAcceptedInvites="/doctor/e_acceptedInvites";
export const deleteSharedPatient="/doctor/removePatient"
export const doctorPendingInvites="/doctor/e_pendingInvites";

// Share Api url
export const cancelShareData="/user/inactivePermissions"
export const activeShareData="/user/activePermissions"
export const removeShareData="/user/removePermissions"
export const doctorDetails="/user/e_doctorDetails";
export const acceptDocInvitation="/user/e_acceptDoctorInvitation";
export const sharedUsersList="/user/e_sharedUsers";
export const patientPendingInvitesList="/user/e_pendingInvites";


export const newEmailReq= "/user/updateEmailRequest";
export const verifyEmailReq = "/user/verifyUpdateEmailRequest";
export const newEmailConfirm= "/user/updateEmail";
export const newEmailConfirmReq= "/user/verifyUpdateEmailRequest";






export const updateUnit = (unit, data) => {
    const keys = Object.keys(data);
    keys.forEach(key => {
        if (unit === 1) {
            if (key === "historicalReadings") {
                if (data["historicalReadings"].length > 0) {
                    data["historicalReadings"].forEach(historicalReading => {
                        if (historicalReading["data"].length > 0) {
                            historicalReading["data"].forEach(reading => {
                                reading["value"]["bloodGlucose"] = reading["value"]["bloodGlucose"] * 18.018;
                                reading["value"]["unit"] = "mgdl/L";
                            })
                        }
                    })
                }
            } else if(key === "hyposAndHypers"){
                if(data["hyposAndHypers"]["hyper"].length > 0){
                    data["hyposAndHypers"]["hyper"].forEach(record => {
                        if(record["data"].length > 0) {
                            record["data"].forEach(reading => {
                                reading["value"]["bloodGlucose"] = reading["value"]["bloodGlucose"] * 18.018
                                reading["value"]["unit"] = "mgdl/L"
                            })
                        }
                    })
                }
                if(data["hyposAndHypers"]["hypo"].length > 0){
                    data["hyposAndHypers"]["hypo"].forEach(record => {
                        if(record["data"].length > 0) {
                            record["data"].forEach(reading => {
                                reading["value"]["bloodGlucose"] = reading["value"]["bloodGlucose"] * 18.01
                                reading["value"]["unit"] = "mgdl/L"
                            })
                        }
                    })
                }
            } else if(key === "bgReadings"){
                if(data["bgReadings"].length > 0){
                    data["bgReadings"].forEach(reading => {
                        reading["value"]["bloodGlucose"] = reading["value"]["bloodGlucose"] * 18.018
                        reading["value"]["unit"] = "mgdl/L"
                    })
                }
            } else if (key === "bgStats") {
                data["bgStats"]["average"] = data["bgStats"]["average"] * 18.018
                data["bgStats"]["highest"] = data["bgStats"]["highest"] * 18.018
                data["bgStats"]["lowest"] = data["bgStats"]["lowest"] * 18.018
            } else if (key === "averageBg") {
                if (data["averageBg"].length > 0) {
                    data["averageBg"].forEach(avgReading => {
                        avgReading["avg"] = avgReading["avg"] * 18.018;
                    })
                }
            } else if (key === "avgPreAndAfterMeal") {
                data["avgPreAndAfterMeal"]["avgPreMeal"] = data["avgPreAndAfterMeal"]["avgPreMeal"] * 18.018
                data["avgPreAndAfterMeal"]["avgAfterMeal"] = data["avgPreAndAfterMeal"]["avgAfterMeal"] * 18.018
            }
        }
    });
    return data;
}
