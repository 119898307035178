import React, { Suspense, useEffect ,useState} from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import GuestRoute from "./utils/routes/guest-route";
import SimpleAlexa from "./utils/routes/simple-route";

import AuthenticatedRoute from "./utils/routes/authenticate-route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import { default as Titles } from "./utils/document-titles";
import { fetchUser } from "./redux/actions/user";
import { safariStatus } from "./redux/actions/auth";

import { useDispatch } from "react-redux";
import $ from "jquery";
import LoginNew from "./components/auth/LoginNew";
import AlexaIntegrationComp from "./components/auth/alexa-integration";

import AidexComp from "./components/patient-dashboard/micro-apps/aidex/aidex-comp";
// import AidexLogin from "./components/auth/aidex-login";



// import Login from "./components/auth/login";
// import Register from "./components/auth/register";
import ForgotPassword from "./components/auth/forget-passwordNew";
// import Login from "./components/auth/login";
import Register from "./components/auth/RegisterNew";
// import Register from "./components/auth/register";
// import ForgotPassword from "./components/auth/forgot-password";

// import ResetPassword from "./components/auth/reset-password";
import ResetPassword from "./components/auth/reset-passwordNew";
import ResetEmail from "./components/auth/resetEmail";
import ResetEmailConfirm from "./components/auth/nweEmailAct";


import ResendVerificationEmail from "./components/auth/resend-verification-emailNew";

import Dashboard from "./components/dashboard";
import History from "./components/history";
import ContactUs from "./components/patient-dashboard/contact.js";

import UserManagement from "./components/admin-dashboard/usermanagement/UserManagement.js";
import Notifications from "./components/admin-dashboard/notifications/NotificationsManagement.js";


import PatientHistory from "./components/clinician-dashboard/patient-details/patient-history";
import Emergency from "./components/emergency";
// import BloodGlucose from "./components/patient-dashboard/micro-apps/blood-glucose";
import BloodGlucoseComp from "./components/patient-dashboard/micro-apps/BloodGlucose/BloodGlucoseComp";
import BloodGlucoseX6 from "./components/patient-dashboard/micro-apps/BloodGlucose/bg-x6";
import CsvToJsonConverter from "./components/patient-dashboard/micro-apps/pdfView";


import Devices from "./components/patient-dashboard/devices";
import BloodPressure from "./components/patient-dashboard/micro-apps/blood-pressure/blood-pressure";
import PulseOximeter from "./components/patient-dashboard/micro-apps/pulse-oximeter/pulse-oximeter";
import WeightScale from "./components/patient-dashboard/micro-apps/weight-scale/weight-scale";
import Temperature from "./components/patient-dashboard/micro-apps/temperature/temperature";
import AccountActivationComp from "./components/auth/account-activation"

import Journal from "./components/patient-dashboard/journal";

import Schedule from "./components/clinician-dashboard/Sechedule/Schedule";
import Patients from "./components/clinician-dashboard/Patients/Patients";
import AddAppointment from "./components/clinician-dashboard/AddAppointment/AddAppointment";

import shareComp from "./components/patient-dashboard/SharePage/ShareComp";
import pharmacy from "./components/patient-dashboard/pharmacy";
import doctor from "./components/patient-dashboard/doctor";
import ExportDataComp from "./components/patient-dashboard/Export/ExportDataComp";

import AddPatient from "./components/doctor-dashboard/AddPatient/AddPatient"

import {getDevices} from "./redux/actions/device";
import { useSelector } from "react-redux";
import PatientList from "./components/doctor-dashboard/PatientList/PatientList";
import DeleteAccount from "./components/auth/DeleteAccount";
import ShareComp from "./components/patient-dashboard/SharePage/ShareComp";
import PendingInviteList from "./components/doctor-dashboard/Pending/PendingInviteList";
import InrComp from "./components/patient-dashboard/micro-apps/INR/InrComp";
import userRoles from "./utils/user-roles";

function App() {
  let user_role = useSelector(({user}) =>(user.userDetails));
  user_role=user_role && user_role.role

  document.title = Titles.default;
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(fetchUser());
    dispatch(safariStatus());
    // dispatch(getDevices())
    console.warn = () => {};

  }, []);

  let doc=localStorage.getItem('doc')
  return (
    <>
      <Router>
        <Suspense fallback={() => <div>loading...</div>}>
          <Switch>
            <GuestRoute exact path={"/auth/login"} component={LoginNew} />

            <GuestRoute exact path={"/auth/register"} component={Register} />
            <GuestRoute
              exact
              path={"/auth/forgotPassword"}
              component={ForgotPassword}
            />
            <GuestRoute
              exact
              path={"/auth/resetPassword"}
              component={ResetPassword}
            />
            <AuthenticatedRoute
              exact
              path={"/auth/updateEmail"}
              component={ResetEmail}
            />
            <AuthenticatedRoute
              exact
              path={"/auth/verifyUpdateEmail"}
              component={ResetEmailConfirm}
            />

            <GuestRoute
              exact
              path={"/user/activate-account"}
              component={AccountActivationComp}
            />

            <SimpleAlexa
              exact
              path={"/auth"}
              component={AlexaIntegrationComp}
            />
            <GuestRoute
              exact
              path={"/auth/resendVerificationEmail"}
              component={ResendVerificationEmail}
            />

          <AuthenticatedRoute
            exact
            path={"/dashboard"}
            component={Dashboard}
          />
          <AuthenticatedRoute
            exact
            path={"/user/deleteAccount"}
            component={DeleteAccount}
          />

            {/* {userRoles.PATIENT===(role!=="" && role) || doc && doc!==""?
            <AuthenticatedRoute exact path={"/history"} component={History} />:<Redirect to="/dashboard"/>} */}
            <AuthenticatedRoute exact path={"/history"} component={History} />
            {/*<AuthenticatedRoute exact path={"/aidex-login"} component={AidexLogin} />*/}
            <AuthenticatedRoute exact path={"/aidex"} component={AidexComp} />


            <AuthenticatedRoute exact path={"/contact"} component={ContactUs} />
            <AuthenticatedRoute exact path={"/admin/userManagement"} component={UserManagement} />
            <AuthenticatedRoute exact path={"/admin/notifications"} component={Notifications} />


            <AuthenticatedRoute exact path={"/emergency"} component={Emergency} />
            <AuthenticatedRoute exact path={"/inr"} component={InrComp} />
            <AuthenticatedRoute exact path={"/blood-glucose"} component={BloodGlucoseComp} />
            <AuthenticatedRoute exact path={"/bg-x6"} component={BloodGlucoseX6} />


            <AuthenticatedRoute exact path={"/blood-pressure"} component={BloodPressure} />
            <AuthenticatedRoute exact path={"/pulse-oximeter"} component={PulseOximeter} />
            <AuthenticatedRoute exact path={"/weight-scale"} component={WeightScale} />
            <AuthenticatedRoute exact path={"/temperature"} component={Temperature} />
            <AuthenticatedRoute exact path={"/export-data"} component={ExportDataComp} />
            {!doc ?
            <AuthenticatedRoute exact path={"/share"} component={shareComp} />
             :null}
            {!doc ?
            <AuthenticatedRoute exact path={"/pharmacy"} component={pharmacy} />
             :null}
            {/* {!doc && doc ==""? */}
            <AuthenticatedRoute exact path={"/online_doctor"} component={doctor} />
            {/* :null} */}


            {/*<AuthenticatedRoute exact path={"/pdfview"} component={CsvToJsonConverter} />*/}
            <AuthenticatedRoute exact path={"/devices"} component={Devices} />
            <AuthenticatedRoute exact path={"/journal"} component={Journal} />
            {/* clinician routes start */}
            <AuthenticatedRoute exact path={"/doctor/patient/history"} component={PatientHistory} />
            <AuthenticatedRoute exact path={"/doctor/patient/record"} component={PatientHistory} />
            <AuthenticatedRoute exact path={"/doctor/appointment/add"} component={AddAppointment} />
            <AuthenticatedRoute exact path={"/doctor/appointment/schedule"} component={Schedule} />
            <AuthenticatedRoute exact path={"/doctor/patient"} component={Patients} />
             {/* clinician routes end */}

            {/* tele-doctor routes start */}
            <AuthenticatedRoute
              exact
              path={"/add-patient"}
              component={AddPatient}
            />
             <AuthenticatedRoute
              exact
              path={"/pending"}
              component={PendingInviteList}
            />
            {/* tele-doctor routes start */}
            <Redirect from="*" to="/auth/login" />
            {/** if route not matched , redirect */}
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
