import * as actions from "../actionTypes";
import { API_BASE_URL, fetchUserURL } from "../../utils/urls";
import http from "../../utils/http-service";
import { loginSuccess, logout } from "./auth";
import { decrypt, encrypt } from "../../utils/encryption";


const fetchUserStart = () => {
  return { type: actions.FETCH_USER_START };
};

const fetchUserFailed = () => {
  return { type: actions.FETCH_USER_FAILED };
};

const fetchUserSuccess = (payload) => {
  return { type: actions.FETCH_USER_SUCCESS, payload };
};

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());

    const token = !!localStorage.getItem("grxAuthToken");
    if (!token) {
      dispatch(fetchUserFailed());
      return;
    }

    const result = await http.get(`${API_BASE_URL}${fetchUserURL}`, {});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      data = decrypt(data);
      data = JSON.parse(data);

      dispatch(loginSuccess({ token: localStorage.getItem("grxAuthToken") }));
      dispatch(fetchUserSuccess({ user: data.user }));
    }

    return result;
  } catch (error) {
    //("error",error.response);
    if(error.response.status===422 || error.response.status === 401){
      // // // // // console.log("window.location.pathname", window.location.pathname);
      // localStorage.setItem("pathname",window.location.pathname);
      let doc=localStorage.getItem('doc')
      localStorage.setItem('grxAuthToken',doc)
    localStorage.removeItem("doc")
      dispatch(logout(true));
      window.location.reload();

    }

    dispatch(fetchUserFailed());
    throw error;
  }
};


const updateUserStart = () => {
  return { type: actions.UPDATE_USER_START };
};

const updateUserFailed = () => {
  return { type: actions.UPDATE_USER_FAILED };
};

const updateUserSuccess = (payload) => {
  return { type: actions.UPDATE_USER_SUCCESS, payload };
};

export const updateUser = (data) => async (dispatch) => {
  dispatch(updateUserStart());

  try {
    let updateData=data


    updateData=JSON.stringify(updateData)

    updateData = encrypt(updateData);


    let result = await http.post(`${API_BASE_URL}/user/e_update`, {"data":updateData});

    let gift = decrypt(result.data.data);
    gift=JSON.stringify(gift)

    dispatch({ type: actions.UPDATE_USER_SUCCESS, payLoad: gift });

    return result;
  } catch (error) {
    if(error.response.status===422 || error.response.status === 401){

      dispatch(logout(true));
      window.location.reload();

    }
    dispatch(updateUserFailed());
    throw error;
  }
};
