import { API_BASE_URL,exportPdfUrl } from "../../utils/urls";
import { FETCH_EXPORT_START, FETCH_EXPORT_FAILED, FETCH_EXPORT_SUCCESS,SAVE_EXPORT_SUCCESS,FETCH_EXPORT_DATA} from "../actionTypes/export";
import http from "../../utils/http-service";
import {decrypt,encrypt} from "../../utils/encryption";
import { withRouter } from "react-router-dom";
import {logout} from "./auth";

export const fetchExportData = (data) => async(dispatch) => {

try {

    let res;

    let bg = data.bg;
    bg=JSON.stringify(bg);
    bg = encrypt(bg);
    let encBg = "bloodGlucose";
    // encBg= encrypt(encBg);

    let bp = data.bp;
    bp=JSON.stringify(bp);

    bp = encrypt(bp);
    let encBp = "bloodPressure";
    // encBp= encrypt(encBp);

    let weight = data.weight;
    weight=JSON.stringify(weight);

    weight = encrypt(weight);
    let encWeight = "weightScale";
    // encWeight= encrypt(encWeight);

    let temp = data.temp;
    temp=JSON.stringify(temp);

    temp = encrypt(temp);
    let enctemp = "thermometer";
    // enctemp= encrypt(enctemp);

    let spo2 = data.spo2;
    spo2=JSON.stringify(spo2);

    spo2 = encrypt(spo2);
    let encSpo2 = "spo2";
    // encSpo2= encrypt(encSpo2);

    if(data.time!==""){
      let time = data.time;
      // time = JSON.stringify(time);
      time = encrypt(time);
      let encTime ="time";
      // encTime = encrypt(encTime);

      let bg = data.bg;
      bg=JSON.stringify(bg);
      bg = encrypt(bg);
      let encBg = "bloodGlucose";
      // encBg= encrypt(encBg);

      let bp = data.bp;
      bp=JSON.stringify(bp);

      bp = encrypt(bp);
      let encBp = "bloodPressure";
      // encBp= encrypt(encBp);

      let weight = data.weight;
      weight=JSON.stringify(weight);

      weight = encrypt(weight);
      let encWeight = "weightScale";
      // encWeight= encrypt(encWeight);

      let temp = data.temp;
      temp=JSON.stringify(temp);

      temp = encrypt(temp);
      let enctemp = "thermometer";
      // enctemp= encrypt(enctemp);

      let spo2 = data.spo2;
      spo2=JSON.stringify(spo2);

      spo2 = encrypt(spo2);
      let encSpo2 = "spo2";
      // encSpo2= encrypt(encSpo2);


      res = await http.get(`${API_BASE_URL}${exportPdfUrl}/?${encTime}=${time}&${encBg}=${bg}&${encBp}=${bp}&${encWeight}=${weight}&${enctemp}=${temp}&${encSpo2}=${spo2}}`);
      //();
    }
    else{
      let starDate = data.start;
      starDate= encrypt(starDate);
      let endDate = data.end;
      endDate= encrypt(endDate);


      res = await http.get(`${API_BASE_URL}${exportPdfUrl}/?start_date=${starDate}&end_date=${endDate}&${encBg}=${bg}&${encBp}=${bp}&${encWeight}=${weight}&${enctemp}=${temp}&${encSpo2}=${spo2}}`);
    }

    let gift = res.data.data;
    gift = decrypt(gift);
    gift = JSON.parse(gift);


 //("Data in Action",gift)

  // updateUnit(unit, res.data.data)

    dispatch({ type: SAVE_EXPORT_SUCCESS, payLoad: gift });


  return res;
} catch (error) {
  // // console.log("ERROR", error.status);
  // if(error.response.status===422 || error.response.status === 401){
  //
  //   dispatch(logout(true));
  //   window.location.reload();
  //
  // }
}
};
